
import ImageMeta from "@/mixins/imageMeta.js";

export default {
  name: "ImageTextSection",
  mixins: [ImageMeta],
  props: ['zone'],
  data() {
    return {
      VideoPlayer: null,
      observer: null
    };
  },
  computed: {
    isVideo() {
      return this.imageAttributes && this.imageAttributes.provider_metadata?.resource_type === 'video';
    },
    imageAttributes() {
      return this.getAttribute(this.zone, 'image.data.attributes');
    },
    videoOptions() {
      if (this.zone.videoOnce) {
        return {
          autoplay: false,
          controls: false,
          loop: false,
          muted: true,
          sources: [
            {
              src: this.imageAttributes ? this.imageAttributes.url : '',
              type: this.imageAttributes ? this.imageAttributes.mime : '',
            }
          ]
        }
      } else {
        return {
          autoplay: true,
          controls: false,
          loop: true,
          muted: true,
          sources: [
            {
              src: this.imageAttributes ? this.imageAttributes.url : '',
              type: this.imageAttributes ? this.imageAttributes.mime : '',
            }
          ]
        }
      }
    }
  },
  created() {
    if (this.imageAttributes && this.imageAttributes.provider_metadata?.resource_type === 'video') {
      import('@/components/VideoPlayer.vue').then(module => {
        this.VideoPlayer = module.default;
      });
    }
  },
  mounted() {
    if (this.zone.videoOnce && this.isVideo) {
      this.setupObserver();
    }
  },
  updated() {
    if (this.zone.videoOnce && this.isVideo) {
      this.setupObserver();
    }
  },
  methods: {
    setupObserver() {
      this.$nextTick(() => {
        if (this.$refs.videoPlayerComponent && !this.observer) {
          const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
          };
          this.observer = new IntersectionObserver(this.handleIntersection, options);
          this.observer.observe(this.$refs.videoPlayerComponent.$el);
        }
      });
    },
    handleIntersection(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (this.$refs.videoPlayerComponent && this.$refs.videoPlayerComponent.play) {
            this.$refs.videoPlayerComponent.play();
          }
          if (this.observer) {
            this.observer.unobserve(entry.target);
          }
        }
      });
    }
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
